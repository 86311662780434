<template>
  <div>
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">Building Info</h5>
        <!-- <p class="text-sm text-body mb-0">
          Features include sorting, searching, pagination, content-editing, and
          row selection.
        </p> -->
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :options.sync="options"
          :items="items"
          :server-items-length="items_count"
          :loading="loading"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-row>
              <v-select
                :items="city_info"
                v-model="select_city"
                class="
                  input-style
                  font-size-input
                  border-radius-md
                  select-style
                  pl-6
                "
                outlined
                single-line
                height="36"
                @change="onCitySelect()"
              >
              </v-select>
              <v-select
                :items="district_info"
                v-model="select_district"
                item-text="district_name"
                item-value="district_code"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  px-2
                "
                outlined
                single-line
                height="36"
                @change="get_regions()"
              >
              </v-select>
              <v-select
                :items="region_info"
                v-model="select_region"
                item-text="region_name"
                item-value="region_code"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  px-2
                "
                outlined
                single-line
                height="36"
              >
              </v-select>

              <v-menu
                ref="start_menu"
                v-model="start_menu"
                :close-on-content-click="false"
                :return-value.sync="start_date"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="py-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="py-0 px-2"
                    v-model="start_date"
                    :label="$t('Start-date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start_date"
                  no-title
                  scrollable
                  color="blue lighten-1"
                  header-color="primary"
                  locale="zh-tw"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="start_menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.start_menu.save(start_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-menu
                ref="end_menu"
                v-model="end_menu"
                :close-on-content-click="false"
                :return-value.sync="end_date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="end_date"
                    class="py-0 px-2"
                    :label="$t('End-date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="end_date"
                  no-title
                  scrollable
                  color="blue lighten-1"
                  header-color="primary"
                  locale="zh-tw"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="end_menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.end_menu.save(end_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-btn
                :ripple="false"
                elevation="0"
                color="#fff"
                class="
                  font-weight-bolder
                  btn-default
                  bg-gradient-default
                  py-5
                  px-6
                  mx-3
                  ms-auto
                "
                small
                @click="onOptionChange()"
              >
                <v-icon class="me-2" size="12">fa fa-search</v-icon>
                {{ $t("Search") }}
              </v-btn>

              <v-btn
                :ripple="false"
                elevation="0"
                color="#fff"
                class="
                  font-weight-bolder
                  btn-default
                  bg-gradient-default
                  py-5
                  px-6
                  mx-3
                  ms-auto
                "
                small
                @click="onReset()"
              >
                <v-icon class="me-2" size="12">fa fa-search</v-icon>
                {{ $t("Reset") }}
              </v-btn>
            </v-row>
          </template>
          <!-- templates -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-2" v-on="on" @click.stop="edit(item)"
                  >mdi-application-edit-outline</v-icon
                >
              </template>
              <span>{{ $t("Edit") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  class="mr-2"
                  v-on="on"
                  @click.stop="export_pdf(item)"
                  >mdi-download-outline</v-icon
                >
              </template>
              <span>{{ $t("Download") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#cb0c9f"
              v-model="page"
              :length="getPageCount()"
              circle
              :total-visible="15"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import TranscriptBase from "./Widgets/TranscriptBase.vue";

import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);
import { mapGetters } from "vuex";

export default {
  name: "building-info-tables",
  data: (vm) => ({
    page: 1,
    pageCount: 10,
    items_count: 0,
    itemsPerPage: 10,
    items: [],
    options: {},
    loading: false,
    edit_dialog: false,
    dialogDelete: false,
    start_menu: false,
    start_date: null,
    end_menu: false,
    end_date: null,
    keyword: "",
    headers: [
      // {
      //   text: vm.$i18n.t("city"),
      //   align: "start",
      //   cellClass: "border-bottom",
      //   sortable: false,
      //   value: "city",
      //   class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      // },
      // {
      //   text: vm.$i18n.t("district"),
      //   value: "district",
      //   class: "text-secondary font-weight-bolder opacity-7",
      //   sortable: false,
      // },
      // {
      //   text: vm.$i18n.t("region"),
      //   value: "region",
      //   class: "text-secondary font-weight-bolder opacity-7",
      //   sortable: false,
      // },
      {
        text: vm.$i18n.t("door_map"),
        value: "door_map",
        class: "text-secondary font-weight-bolder opacity-7",
        width: "20%",
        sortable: false,
      },
      {
        text: vm.$i18n.t("reg_date"),
        value: "reg_date",
        class: "text-secondary font-weight-bolder opacity-7",
        sortable: true,
      },
      {
        text: vm.$i18n.t("right_type"),
        value: "right_type",
        class: "text-secondary font-weight-bolder opacity-7",
        sortable: false,
      },

      {
        text: vm.$i18n.t("debt_unit"),
        value: "debt_amount_unit",
        class: "text-secondary font-weight-bolder opacity-7",
        sortable: false,
      },
      // {
      //   text: vm.$i18n.t("debt_ratio"),
      //   value: "debt_ratio_num",
      //   sortable: false,
      //   class: "text-secondary font-weight-bolder opacity-7",
      // },
      {
        text: vm.$i18n.t("debt_total"),
        value: "debt_amount_total_num",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
      {
        text: vm.$i18n.t("area"),
        value: "area_num",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
      {
        text: vm.$i18n.t("floors"),
        value: "floors_num",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
      {
        text: vm.$i18n.t("completion_date"),
        value: "completion_date",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
      {
        text: vm.$i18n.t("owners_count"),
        value: "owners_count",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
      {
        text: vm.$i18n.t("owners_names"),
        value: "owners_names",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
      {
        text: vm.$i18n.t("owners_ids"),
        value: "owners_ids",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
      // {
      //   text: vm.$i18n.t("owners_addresses"),
      //   value: "owners_addresses",
      //   sortable: false,
      //   class: "text-secondary font-weight-bolder opacity-7",
      // },
      {
        text: vm.$i18n.t("actions"),
        value: "actions",
        // align: "right",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
    ],
    clear_nt_unit: ["新臺幣", "新台幣", "元正", "元整"],
    clear_area: ["平方公尺"],
    clear_floor: ["層"],
  }),
  mixins: [TranscriptBase],
  components: {},
  mounted() {
    this.get_districts();
    this.getBuildingInfo();
  },
  methods: {
    ...mapGetters(["getToken"]),
    onReset() {
      console.log("on Reset");
      this.page = 1;
      this.start_date = null;
      this.end_date = null;
      this.keyword = "";
      this.select_city = "H";
      this.getBuildingInfo();
    },
    onOptionChange() {
      this.page = 1;
      this.getBuildingInfo();
    },
    onAddCustomer() {
      this.isCreate = true;
      this.editedIndex = -1;
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.edit_dialog = true;
    },
    edit(item) {
      alert("Edit未實做");
      this.isCreate = false;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.originItem = JSON.parse(JSON.stringify(item));
      this.edit_dialog = true;
    },
    export_excel() {
      console.log("Export select as excel ");
    },

    export_pdf(item) {
      console.log("create pdf and download", item.lbkey);
      this.download(item);
    },
    close() {
      this.onClear();
      this.edit_dialog = false;
    },
    onActionDone(msg) {
      this.close();
      this.getBuildingInfo();
      this.showSuccessAlert(msg); //"Add Customer");
    },
    showSuccessAlert(message) {
      this.$swal("Good job!", message, "success");
    },
    onRestore() {
      console.log("on restore", this.originItem);
      this.editedItem = JSON.parse(JSON.stringify(this.originItem));
    },
    onClear() {
      this.editedIndex = -1;
      this.originItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
    },
    getPageCount() {
      return Math.ceil(this.items_count / this.itemsPerPage);
    },
    clear_unit(item, unit_array) {
      // debt_amount_total this.clear_nt_unit
      unit_array.forEach((name) => {
        item = item.replace(name, "");
      });
      item = item.replace(/\s/g, "");
      return item;
    },
    getBuildingInfo() {
      const token = this.getToken();
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/building-infos/`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        params: {
          page: this.page,
          size: this.itemsPerPage,
          city: this.select_city,
        },
      };
      if (this.keyword != null && this.keyword.length > 0) {
        config["params"]["keyword"] = this.keyword;
      }
      if (this.select_district != null) {
        config["params"]["district"] = this.select_district;
      }

      if (this.start_date != null) {
        config["params"]["start"] = this.start_date;
      }
      if (this.end_date != null) {
        config["params"]["end"] = this.end_date;
      }
      this.axios
        .get(url, config)
        .then(function (response) {
          console.info(response.data);
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setResult(result) {
      this.items_count = result.total;
      this.items = result.items;
    },

    download(item) {
      const lbkey = item.lbkey;
      const token = this.getToken();
      const currentObj = this;
      const filename = `${item.city}${item.district}.pdf`;

      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/building-infos/pdf?lbkey=${lbkey}`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        responseType: "blob", // important
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.forceFileDownload(response, filename);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    forceFileDownload(response, filename) {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
    },
  },
  watch: {
    page() {
      this.getBuildingInfo();
    },
  },

  computed: {},
};
</script>
<style scoped>
/* @import "../../../assets/css/jub.css"; */

.download_txt:hover {
  color: blue !important;
  text-decoration: underline;
}
</style>
