<script>
import CommonUtility from "@/util/CommonUtility.js";
import { setting_type } from "@/definitions.js";
import FileCommon from "@/views/Components/FileCommon.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import CDRBase from "@/views/Pages/General/Widgets/CDRBase.vue";
export default {
  name: "transcript-base",
  data: (vm) => ({
    is_missing_o: false,
    is_missing_c: false,
    setting_type: setting_type,
    show_tp: false,
    loading_tp: false,
    page: 1,
    pageCount: 20,
    items_count: 0,
    itemsPerPage: 20,
    items: [],
    options: {},
    loading: false,
    keyword: null,
    headers: [
      {
        text: vm.$i18n.t("lbkey"),
        align: "start",
        cellClass: "border-bottom",
        sortable: false,
        value: "lbkey",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        width: "15%",
      },
      {
        text: vm.$i18n.t("owners_num"),
        align: "start",
        cellClass: "border-bottom",
        sortable: false,
        value: "owners_num",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        width: "10%",
      },
      {
        text: vm.$i18n.t("creditors_num"),
        align: "start",
        cellClass: "border-bottom",
        sortable: false,
        value: "creditors_num",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        width: "10%",
      },
      {
        text: vm.$i18n.t("missing_o"),
        align: "start",
        cellClass: "border-bottom",
        sortable: false,
        value: "missing_o",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        width: "10%",
      },
      {
        text: vm.$i18n.t("missing_c"),
        cellClass: "border-bottom",
        value: "missing_c",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        sortable: false,
        width: "10%",
      },
      {
        text: vm.$i18n.t("private"),
        cellClass: "border-bottom",
        value: "private",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        sortable: false,
        width: "20%",
      },
      {
        text: vm.$i18n.t("actions"),
        cellClass: "border-bottom",
        align: "end",
        value: "actions",
        // align: "right",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
    ],
    base_url: "/api/v1/transcripts/buildings/",
  }),
  mixins: [FileCommon, CDRBase],
  watch: {
    page() {
      this.getTranscript();
    },
  },
  computed: {
    ...mapState(["district_map"]),
  },
  methods: {
    ...mapActions(["setDistrict"]),
    ...mapGetters(["getToken"]),
    getDistrictName(item) {
      const lbkey = item["lbkey"];
      const district = item["district"];
      const cdrs = CommonUtility.getCDRInfo(lbkey);
      if (cdrs.length > 0) {
        return `${district} (${cdrs[1]})`;
      }
      return district;
    },
    getRegionName(item) {
      const region_name = item["region_name"];
      const region_code = item["region_code"];
      return `${region_name} (${region_code})`;
    },
    getArea(item) {
      let area = item["mark"].area;
      if (area == null) {
        return "";
      }
      area = area.replace(",", "");
      return CommonUtility.extractNumberFromSquareMeters(area);
    },
    getScopeArea(item) {
      if (item["scope"] != null && item["mark"].area != null) {
        const scope = item["scope"];
        const area = CommonUtility.extractNumberFromSquareMeters(
          item["mark"].area
        );
        const scope_area = CommonUtility.convertChineseFractionToFloat(scope);

        return (scope_area * area).toFixed(3);
      }
      return 0;
    },
    getCreditorCount(item) {
      // console.log(item);
      if (item["other_rno"] != null) {
        const creditors = item["other_rno"];
        return JSON.parse(creditors).length;
      }
      return 0;
    },
    getTranscript() {
      const token = this.getToken();
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + this.base_url;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        params: {
          page: this.page,
          size: this.itemsPerPage,
        },
      };
      if (this.keyword.length > 0) {
        config["params"]["keyword"] = this.keyword;
      }
      this.loading = true;
      this.axios
        .get(url, config)
        .then(function (response) {
          // console.log(response.data);
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setResult(result) {
      this.items_count = result.total;
      this.items = result.items;
    },
    getPageCount() {
      return Math.ceil(this.items_count / this.itemsPerPage);
    },
    clearItems() {
      this.items_count = 1;
      this.items = [];
    },
    get_transcript_by_key(lbkey) {
      const token = this.getToken();

      let currentObj = this;
      let url = `${process.env.VUE_APP_SERVER_URL}/api/v1/transcripts/${lbkey}`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };

      this.loading_tp = true;
      this.axios
        .get(url, config)
        .then(function (response) {
          console.debug(response.data);
          currentObj.setViewTranscript(response.data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          currentObj.loading_tp = false;
        });
    },
    export_pdf(lbkey) {
      const token = this.getToken();

      let currentObj = this;
      let url = `${process.env.VUE_APP_SERVER_URL}/api/v1/transcripts/${lbkey}/pdf`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        responseType: "blob", // important
      };

      this.loading_tp = true;
      this.axios
        .get(url, config)
        .then(function (response) {
          const filename = `${lbkey}.pdf`;
          currentObj.forceFileDownload(response, filename);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          currentObj.loading_tp = false;
        });
    },
  },
};
</script>
