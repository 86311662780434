<script>
export default {
  name: "file-common",
  data: () => ({}),
  methods: {
    getFilename(res) {
      if (res.headers) {
        let content = res.headers["content-disposition"]; //获取到Content-Disposition;filename
        let regFileNames = content.match(/=(.*)$/)[1]; //文件名称  截
        return regFileNames.replace(/\s/g, "");
      }

      return "temp.csv";
    },
    forceFileDownload(response, filename) {
      if (filename == null) {
        filename = this.getFilename(response);
      }

      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
