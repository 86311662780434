<script>
import { city_info } from "@/cdr.js";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "cdr-base",
  data: () => ({
    select_city: "H",
    city_info: city_info,
    select_district: "01",
    district_info: [],
    select_region: null,
    region_info: [],
  }),
  computed: {
    ...mapState(["district_map", "region_map"]),
  },
  methods: {
    ...mapActions(["setDistrict", "setRegion"]),
    ...mapGetters(["getToken"]),

    onCitySelect() {
      this.district_info = [];
      this.region_info = [];
      this.get_districts();
    },
    getDistrictByCity(city) {
      if (Object.prototype.hasOwnProperty.call(this.district_map, city)) {
        return this.district_map[city];
      }

      return [];
    },
    getRegionByCityDistrict(city, district) {
      const cd = city + "_" + district;
      if (Object.prototype.hasOwnProperty.call(this.region_map, cd)) {
        return this.region_map[cd];
      }

      return [];
    },
    add_all_option_region(data) {
      const ALL_ID_STR = "9999";
      let all_items = [
        {
          cdr: "Z_99_9999",
          city_code: "Z",
          city_name: "全部",
          district_code: "99",
          district_name: "全部",
          id: 9999,
          region_code: ALL_ID_STR,
          region_name: "全部",
        },
      ];
      Array.prototype.push.apply(all_items, data);
      this.region_info = all_items;
      this.select_region = ALL_ID_STR;
    },
    get_districts() {
      const city = this.select_city;
      const token = this.getToken();

      const districts = this.getDistrictByCity(city);
      if (districts != null && districts.length > 0) {
        this.district_info = districts;
        this.select_district = districts[0].district_code;
        this.get_regions();
        return;
      }
      console.log("get district data from server");

      let currentObj = this;
      let url = `${process.env.VUE_APP_SERVER_URL}/api/v1/cdrs/districts`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        params: {
          city: city,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          // console.log(response.data);
          currentObj.district_info = response.data;
          currentObj.select_district = response.data[0].district_code;
          const data = {
            city: city,
            districts: response.data,
          };
          currentObj.setDistrict(data);
          currentObj.get_regions();
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {});
    },
    get_regions() {
      const city = this.select_city;
      const district = this.select_district;
      const currentObj = this;
      const token = this.getToken();

      const regions = this.getRegionByCityDistrict(city, district);
      if (regions != null && regions.length > 0) {
        // this.region_info = regions;
        // this.select_region = regions[0].region_code;
        currentObj.add_all_option_region(regions);
        return;
      }
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/cdrs/regions`;
      console.log("get region data from server", url);
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        params: {
          city: city,
          district: district,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          // console.log(response.data);
          // currentObj.region_info = response.data;
          // currentObj.select_region = response.data[0].region_code;
          currentObj.add_all_option_region(response.data);
          const data = {
            city: city,
            district: district,
            regions: response.data,
          };
          currentObj.setRegion(data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {});
    },
  },
};
</script>
